import { Link } from 'gatsby';
import React from 'react';
import { ContentWrapper } from '../Shared/Wrapper/ContentWrapper';

export function ExhibitRegister() {
  return (
    <ContentWrapper>
      <section
        className="flex flex-col justify-center items-center"
        style={{ height: 400 }}
      >
        <section className="mb-5 text-center">
          <h1 className="font-hero-rounded text-primary-2 text-6xl">
            Virtual Exhibit
          </h1>
          <p className="mb-3">
            To view the virtual exhibit, you need sign in first
          </p>
        </section>

        <section className="flex justify-center items-center">
          <Link
            to="/auth?exhibit=true"
            className="uppercase bg-primary-1 px-8 py-3 
										 rounded-lg text-white 
        						 transform hover:-translate-y-0.5 transition-all duration-150 hover:shadow-lg"
          >
            Sign in
          </Link>
        </section>
      </section>
    </ContentWrapper>
  );
}
