import { navigate } from 'gatsby-link';
import React, { useEffect } from 'react';

export function ExhibitRedirect() {
  useEffect(() => {
    navigate(`/exhibit/lobby`);
  }, []);

  return null;
}
