import React from 'react';
import { Router } from '@reach/router';
import { PublicLayout } from '../components/Shared/PublicLayout';
import SeoHeader from '../components/Shared/SeoHeader';
import { NotFoundRedirect } from '../components/Shared/Redirects/NotFoundRedirect';
import { Exhibit } from '../components/Exhibit';
import { ExhibitMain } from '../components/Exhibit/ExhibitMain';
import { ExhibitType } from '../enums/ExhibitEnum';

export default function ExhibitPage() {
  return (
    <PublicLayout disableEmphasize disableFooter>
      <SeoHeader title="Virtual Exhibit" />

      <Router basepath="/exhibit">
        <Exhibit path="/" />
        <ExhibitMain type={ExhibitType.Lobby} path="/lobby" />
        <ExhibitMain type={ExhibitType.Main} path="/dost1" />
        <ExhibitMain type={ExhibitType.Kabuhayan} path="/kabuhayan-cluster-a" />
        <ExhibitMain
          type={ExhibitType.KabuhayanExt}
          path="/kabuhayan-cluster-b"
        />
        <ExhibitMain type={ExhibitType.Kalusugan} path="/kalusugan" />
        <ExhibitMain type={ExhibitType.Kaayusan} path="/kaayusan" />
        <ExhibitMain type={ExhibitType.Kinabukasan} path="/kinabukasan" />
        <NotFoundRedirect path="*" />
      </Router>
    </PublicLayout>
  );
}
