import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Wrapper } from '../Shared/Wrapper';
import { Loader } from '../Shared/Loaders/Loader';
import { getUser } from '../../services/auth';
import { ExhibitRegister } from './ExhibitRegister';
import { ExhibitRedirect } from './ExhibitRedirect';

export function Exhibit(_: RouteComponentProps) {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isAvailable, setIsAvailable] = useState<boolean>(false);

  useEffect(() => {
    const user = getUser();
    if (user.name) setIsAvailable(true);
    setLoading(false);
  }, []);

  return (
    <Wrapper>
      {isLoading ? (
        <section className="flex justify-center items-center">
          <Loader />
        </section>
      ) : isAvailable ? (
        <ExhibitRedirect />
      ) : (
        <ExhibitRegister />
      )}
    </Wrapper>
  );
}
