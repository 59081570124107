import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { ExhibitType } from '../../enums/ExhibitEnum';
import { NotFound } from '../NotFound';
import { Loader } from '../Shared/Loaders/Loader';
import SeoHeader from '../Shared/SeoHeader';
import { getUser } from '../../services/auth';
import { ExhibitRegister } from './ExhibitRegister';

type Props = RouteComponentProps & {
  type: ExhibitType;
};

export function ExhibitMain({ type }: Props) {
  const title = `${
    type !== ExhibitType.KabuhayanExt ? type : 'KABUHAYAN'
  } - Virtual Exhibit`;

  const [loading, setLoading] = useState<boolean>(true);
  const [url, setUrl] = useState<string | null>(null);
  const [isAvailable, setIsAvailable] = useState<boolean>(false);

  useEffect(() => {
    const user = getUser();
    if (user.name) setIsAvailable(true);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (type === ExhibitType.Kinabukasan)
      setUrl('https://dost1.com/DOST360/Kinabukasan');
    if (type === ExhibitType.Kalusugan)
      setUrl('https://dost1.com/DOST360/kalusugan/');
    if (type === ExhibitType.Kaayusan)
      setUrl('https://dost1.com/DOST360/kaayusan/');
    if (type === ExhibitType.Kabuhayan)
      setUrl('https://dost1.com/DOST360/kabuhayan/Kabuhayan%20A/');
    if (type === ExhibitType.KabuhayanExt)
      setUrl('https://dost1.com/DOST360/kabuhayan/Kabuhayan%20B/');
    if (type === ExhibitType.Main)
      setUrl('https://dost1.com/DOST360/mainbooth/');
    if (type === ExhibitType.Lobby) setUrl('https://nstwlobby.dost1.ph/');

    setLoading(false);
  }, [type]);

  return (
    <>
      <SeoHeader title={title} />

      {loading ? (
        <section className="flex justify-center items-center mt-10">
          <Loader />
        </section>
      ) : url !== null ? (
        isAvailable ? (
          <iframe
            src={url}
            title={type}
            style={{ display: 'block', width: '100%', height: '100vh' }}
          />
        ) : (
          <ExhibitRegister />
        )
      ) : (
        <NotFound />
      )}
    </>
  );
}
